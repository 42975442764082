
import store from './store';

class CommonHelper {
    constructor() { }

    getCurrentToken() {
        return localStorage.getItem('token');
    }

    setCurrentToken(token) {
        localStorage.setItem('token', token);
    }

    getVerificationToken() {
        const verificationStr = localStorage.getItem('verification');
        return verificationStr ? JSON.parse(verificationStr) : {};
    }

    setVerificationToken(value) {
        localStorage.setItem('verification', JSON.stringify({
            verificationToken: value.verificationToken,
            verificationSource: value.verificationSource,
            verificationType: value.verificationType
        }));
    }

    logout() {
        localStorage.removeItem('token');
        window.location.href = '/'
    }

    showAlert(type, message) {
        store.commit('set', ['alert', { type: type, message: message, show: true }]);
        setTimeout(() => {
            store.commit('set', ['alert', { show: false }]);
        }, 5000);
    }

    showLoading() {
        store.commit('set', ['setLoading', true]);
    }

    hideLoading() {
        store.commit('set', ['setLoading', false]);
    }

    setUser(user) {
        store.commit('set', ['user', user]);
    }

    getUser() {
        return store.state.user.name ? store.state.user : null
    }

    handleError(error) {
        console.log(error);
        commonHelper.hideLoading();
        if (error.response) {
            if (error.response.status == 401) {
                commonHelper.showAlert('error', 'Oturum Sonlandırıldı!');
                commonHelper.logout();
                return
            }
            if (error.response.data && error.response.data.success === false) {
                commonHelper.showAlert('error', error.response.data.message);
            }
        } else {
            commonHelper.showAlert('error', error.message);
        }

        return Promise.reject(error);
    }

    getCurrentLanguage() {
        return localStorage.getItem('lang') || 'tr';
    }

    async setLanguages() {
        commonHelper.showLoading();
        await window.$axios.get('common/languages').then(function (response) {
            commonHelper.hideLoading();
            if (response && response.data) {
                store.commit('set', ['languages', response.data]);
            }
        });
    }

    async setLocalizations(lang) {
        localStorage.setItem('lang', lang);
        commonHelper.showLoading();
        await window.$axios.get(`common/localizations?type=ui&language=${lang}`).then(function (response) {
            commonHelper.hideLoading();
            if (response && response.data) {
                store.commit('set', ['localization', response.data]);
                window.$i18n.setLocaleMessage(lang, response.data[lang]);
                window.$i18n.locale = lang;
            }
        }).catch(this.handleError)
    }

    async configureUser() {
        await this.setLanguages();
        const token = localStorage.getItem('token');
        const language = localStorage.getItem('lang') || 'tr';
        await this.setLocalizations(language);

        if (!token) { return }
        commonHelper.showLoading();
        await window.$axios.get('auth/me', { 'Authorization': token }).then(function (response) {
            if (response && response.data) {
                store.commit('set', ['user', response.data]);
                commonHelper.hideLoading();
            }
        }).catch(this.handleError)
    }

    toDateAndTimeString(date) {
        const dt = (typeof date) === 'string' ? new Date(date) : date;
        const padL = (nr, chr = `0`) => `${nr}`.padStart(2, chr);
        
        return `${
            padL(dt.getDate())}/${
            padL(dt.getMonth()+1)}/${
            dt.getFullYear()} ${
            padL(dt.getHours())}:${
            padL(dt.getMinutes())}`;
    }

    getNeonEffect(borderType, color) {
        return borderType === 'neon' ?  {
            boxShadow: `0 0 .01rem #fff,
            0 0 .1rem ${color},
            0 0 1rem ${color},
            0 0 0.8rem ${color},
            0 0 0.8rem ${color},
            inset 0 0 0.3rem ${color}`
        } : {}
    }
}

export let commonHelper = new CommonHelper();