<template>
    <v-app>
      <v-main>
        <v-app-bar app clipped-left  flat dark color="secondary">
          <v-toolbar-title style="cursor: pointer;" @click="$router.push('/')">
            <img src="/logo.png" width="100px" class="ml-11" />
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-title>
            <v-menu :rounded="true" offset-y class="language-menu">
              <template v-slot:activator="{ attrs, on }">
                <div class="white--text ma-1 mr-7" v-bind="attrs" v-on="on">
                   <img width="25" :src="`https://flagicons.lipis.dev/flags/4x3/${selectedLang.code === 'en' ? 'us' : selectedLang.code}.svg`"/>
                   <span class="ml-2 language-text">{{ selectedLang.name  }}</span>
                </div>
              </template>
              <v-list>
                <v-list-item class="language-list" v-for="(lang, index) in this.$store.state.languages" :key="index" link @click="selectLang(lang)">
                  <div class="black--text ma-1">
                    <img width="25" :src="`https://flagicons.lipis.dev/flags/4x3/${lang.code === 'en' ? 'us' : lang.code}.svg`"/>
                    <span class="ml-2 language-text">{{ lang.name  }}</span>
                  </div>
                </v-list-item>
              </v-list>
          </v-menu>
          </v-toolbar-title>
          <v-toolbar-title>
            <span class="user">{{ $t('General.Welcome') }}, {{ $store.state.user.name }} {{ $store.state.user.surname }}</span>
          </v-toolbar-title>
          <v-toolbar-items>
            <v-icon size="x-large" color="white" class="ml-3 mr-3" @click="$router.push('/profile')">mdi-account</v-icon>
            <v-icon size="x-large" color="white" @click="logout">mdi-logout</v-icon>
          </v-toolbar-items>
        </v-app-bar>
        <menu-sidebar></menu-sidebar>
        <router-view></router-view>
      </v-main>
  </v-app>
</template>
<script>
import MenuSidebar from '@/components/MenuSidebar.vue';
export default {
  name: 'DashboardLayout',
  components: {
    MenuSidebar
  },
  data() {
    return {
      selectedLang: {}
    }
  },
  methods: {
    logout() {
      localStorage.removeItem('token');
      localStorage.removeItem('lang');
      this.$store.commit('set', ['user', {}]);
      this.$router.push('/login');
    },
    async selectLang(lang) {
      this.selectedLang = lang;
      await this.$commonHelper.setLocalizations(lang.code);
    }
  },
  mounted() {
    const langCode = this.$commonHelper.getCurrentLanguage()
    this.selectedLang = this.$store.state.languages.find(l => l.code === langCode);
  },
};
</script>
<style scoped>
.user {
  font-size: 17px;
  font-weight: 400;
  padding-right: 5px;
}
.dashboard-router {
  padding: 10px;
}
.breadcrumb-component {
  background-color: #f5f5f5;
}
.v-main__wrap {
  background-color: rgb(235, 233, 233);
}
.language-menu {
  margin-top: 1rem;
  margin-right: 1rem;
}
.language-list {
  min-width: 150px;
}
.language-text {
  font-size: medium;
}
</style>
