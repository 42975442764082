<template>
    <v-dialog v-if="isOpen" v-model="isOpen" persistent width="unset">
      <v-card>
        <v-card-title class="text-h5">{{ $t('General.AreYouSure') }}</v-card-title>
        <v-card-text>{{ title }}</v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red white--text" @click="$emit('close')">
                {{ $t('General.Cancel') }}
            </v-btn>
            <v-btn color="green white--text" @click="$emit('onDelete')">
                {{ $t('General.Approve') }}
            </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
    
  <script>
    export default {
      name: 'DeleteDialog',
      props: {
        title: String,
        value: Boolean,
        model: {
          prop: "value"
        }
      },
      computed: {
        isOpen() {
          return this.value;
        }
      }
    }
  </script>
    