<template>
  <v-row>
    <v-col cols="12" :md="md" :style="{padding: padding}">
      <v-select :rules="[rules.required]" v-model="modelLocal.city" :items="cities" item-text="city" item-value="city" outlined dense :label="$t('Parameters.City')" @input="cityChanged" />
    </v-col>
    <v-col cols="12" :md="md" :style="{padding: padding}">
      <v-select :rules="[rules.required]" v-model="modelLocal.district" :items="districts" outlined dense :label="$t('Parameters.District')" />
    </v-col>
    <v-col cols="12" :md="md" :style="{padding: padding}">
      <v-text-field :rules="[rules.required, rules.length(5)]" v-model="modelLocal.address" outlined dense :label="$t('Parameters.AddressDetail')"></v-text-field>
    </v-col>
    <v-col cols="12" :md="md" :style="{padding: padding}">
      <v-text-field :rules="[rules.zipCode]" v-model="modelLocal.zipCode" outlined dense :label="$t('Parameters.ZipCode')" :maxlength="5"></v-text-field>
    </v-col>
    <slot></slot>
  </v-row>
</template>
  
<script>
  import validationRules from '../rules';
  export default {
    name: 'AddressComponent',
    props: {
      padding: {
        type: String,
        default: '12px'
      },
      md: {
        type: String,
        default: '12'
      },
      value: {
        type: Object,
        default: () => {}
      },
      model: {
        prop: 'value',
        event: 'valueChange'
      }
    },
    computed: {
      modelLocal: {
        get: function() {
            return this.value;
        },
        set: function(value) {
            this.$emit('valueChange', value)
        }
      }
    },
    data: () => ({
        rules: validationRules.rules,
        cities: [],
        districts: [],
        firstSet: true
    }),
    async mounted() {
        this.cities = await this.$api.get('common/statics?code=cities');
        if (this.modelLocal.city) {
            this.cityChanged(this.modelLocal.city);
        }
    },
    methods: {
        cityChanged(val) {
            if (this.firstSet) {
                this.firstSet = false;
            } else {
                this.modelLocal.district = '';
            }
            if (val) {
                const city = this.cities.find(c => c.city == val);
                this.districts = city ? city.districts : [];
            } else {
                this.districts = [];
            }
        }
    }
  }
</script>
    