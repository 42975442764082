<template>
    <v-app>
      <v-main class="all-area">
        <v-app-bar app clipped-left flat dark color="secondary">
          <v-toolbar-title style="cursor: pointer;" @click="$router.push('/')">
            <img src="/logo.png" width="100px" class="ml-11" />
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-title style="cursor: pointer;">
            {{ $t('General.DesignPageTitle') }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-label dark>{{ $t('General.Scale') }}</v-label>
          <v-slider class="mt-6 mr-1" dark v-model="sliderValue" step="10" ticks @input="changeScale"></v-slider>
          <v-toolbar-items>
            <QuickDesigns class="mt-3 mr-1"></QuickDesigns>
            <v-icon dark color="white" size="x-large" @click="save" class="ml-3 mr-3">mdi-content-save</v-icon>
            <router-link class="preview-btn" :to="`/page/${$store.state.user.customerCode}`" target="_blank">{{ $t('General.Preview') }}</router-link>
          </v-toolbar-items>
        </v-app-bar>
        <router-view></router-view>
      </v-main>
  </v-app>
</template>
<script>
import QuickDesigns from '@/components/QuickDesigns.vue';
export default {
  name: 'DashboardLayout',
  components: {
    QuickDesigns
  },
  data() {
    return {
      sliderValue: 100
    }
  },
  methods: {
    async save() {
      await this.$api.post('customer/my-components', {
        designId: this.$route.params.id,
        components: this.$store.state.designComponents,
        screen: this.$store.state.screen
      });
      this.$commonHelper.showAlert('success', this.$t('General.SuccessSaveDesign'));
    },
    changeScale(val) {
      const value = parseFloat(val / 100);
      this.$store.commit('set', ['scale', value]);
    }
  }
};
</script>
<style>
.all-area {
  position: absolute;
}
.preview-btn {
  margin-top: 1.2rem;
  font-size: 17px;
  color: white !important;
}
</style>
