import DashboardLayout from '@/layouts/DashboardLayout.vue';
import AuthLayout from '@/layouts/AuthLayout.vue';
import NotFound from '@/views/NotFoundPage.vue';
import DesignLayout from '@/layouts/DesignLayout.vue';

const routes = [
  {
    path: '/page/:code',
    component: () => import('../views/Preview.vue')
  },
  {
    path: '/designs/:id',
    component: DesignLayout,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '/designs/:id',
        name: 'design',
        component: () => import('../views/Design.vue')
      }
    ]
  },
  {
    path: '/',
    redirect: 'dashboard',
    component: DashboardLayout,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('../views/Dashboard.vue')
      },
      {
        path: '/subscription',
        name: 'subscription',
        component: () => import('../views/SubscriptionInfo.vue')
      },
      {
        path: '/verify-email',
        name: 'verify-email',
        component: () => import('../views/VerifyEmail.vue')
      },
      {
        path: '/users',
        name: 'users',
        component: () => import('../views/Users.vue')
      },
      {
        path: '/profile',
        name: 'profile',
        component: () => import('../views/Profile.vue')
      },
      {
        path: '/customer-info',
        name: 'customer-info',
        component: () => import('../views/CustomerInfo.vue')
      },
      {
        path: '/invoice-info',
        name: 'invoice-info',
        component: () => import('../views/InvoiceInfo.vue')
      },
      {
        path: '/designs',
        name: 'designs',
        component: () => import('../views/Designs.vue')
      },
      {
        path: '/payment-history',
        name: 'payment-history',
        component: () => import('../views/PaymentHistory.vue')
      },
      {
        path: '/invoice-history',
        name: 'invoice-history',
        component: () => import('../views/InvoiceHistory.vue')
      }
    ]
  },
  {
    path: '/',
    redirect: 'login',
    component: AuthLayout,
    children: [
      {
        path: '/login',
        name: 'login',
        component: () => import('../views/Login.vue')
      },
      {
        path: '/verify-login',
        name: 'verify-login',
        component: () => import('../views/VerifyLogin.vue')
      },
      {
        path: '/new-customer',
        name: 'new-customer',
        component: () => import('../views/NewCustomer.vue')
      },
      {
        path: '/verify-email',
        name: 'verify-email',
        component: () => import('../views/VerifyEmail.vue')
      },
      {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import('../views/ForgotPassword.vue')
      },
      {
        path: '/set-password',
        name: 'set-password',
        component: () => import('../views/SetPassword.vue')
      },
      {
        path: '/customer-contract',
        name: 'customer-contract',
        component: () => import('../views/CustomerContract.vue')
      },
      {
        path: '/payment-result',
        name: 'payment-result',
        component: () => import('../views/PaymentResult.vue')
      },
      { path: '*', component: NotFound }
    ]
  }
];

export default routes;
