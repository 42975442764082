<template>
  <div id="notfound">
		<div class="notfound">
			<div class="notfound-404">
				<h1>Oops!</h1>
			</div>
			<h2>{{ $t('General.NotFoundTitle') }}</h2>
			<p>{{ $t('General.NotFoundDescription') }}</p>
			<a href="/">{{ $t('General.HomePage') }}</a>
		</div>
	</div>
</template>
  
<script> 
  export default {
    name: 'not-found'
  };
</script>
<style scoped>
#notfound {
  position: relative;
  height: 100vh;
  background-color: '#263C6F';
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.notfound {
  max-width: 410px;
  width: 100%;
  text-align: center;
}

.notfound .notfound-404 {
  height: 320px;
  position: relative;
  z-index: -1;
}

.notfound .notfound-404 h1 {
  font-family: 'Montserrat', sans-serif;
  font-size: 230px;
  margin: 0px;
  font-weight: 900;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  -webkit-background-clip: text;
  background-size: cover;
  background-position: center;
  color: #3fa7dd;
}


.notfound h2 {
  font-family: 'Montserrat', sans-serif;
  color: rgb(186, 182, 182);
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 0;
}

.notfound p {
  font-family: 'Montserrat', sans-serif;
  color: rgb(186, 182, 182);
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 20px;
  margin-top: 0px;
}

.notfound a {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  text-decoration: none;
  text-transform: uppercase;
  background: #3fa7dd;
  display: inline-block;
  padding: 15px 30px;
  border-radius: 15px;
  color: #fff;
  font-weight: 700;
  -webkit-box-shadow: 0px 4px 15px -5px #0046d5;
          box-shadow: 0px 4px 15px -5px #0046d5;
}


@media only screen and (max-width: 767px) {
    .notfound .notfound-404 {
      height: 142px;
    }
    .notfound .notfound-404 h1 {
      font-size: 112px;
    }
}
</style>
  