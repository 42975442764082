<template>
  <v-navigation-drawer
    v-model="drawer"
    :mini-variant.sync="mini"
    app permanent light
  >
  <v-app-bar flat dark color="secondary">
    <v-icon class="mr-3" dark @click.stop="mini = !mini;">mdi-menu</v-icon>
    <v-toolbar-title style="cursor: pointer;" @click="$router.push('/')" absolute>
      <img src="/logo.png" width="100px" />
    </v-toolbar-title>
  </v-app-bar>
    <v-list dense>
      <v-list-item-group v-for="(menu, index) in menus" v-bind:key="index">
        <v-list-item :to="menu.link" :target="menu.target">
          <v-list-item-icon>
            <v-icon color="secondary">{{ menu.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="secondary-color">{{ menu.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      <v-divider></v-divider>
     </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  data() {
    return {
      drawer: true,
      mini: false,
      menus: []
    };
  },
  mounted() {
    this.menus = [
      {
        title: this.$t('Menu.DesignPages'),
        icon: 'mdi-xml',
        link: '/designs'
      },
      {
        title: this.$t('General.CustomerInfo'),
        icon: 'mdi-office-building',
        link: '/customer-info'
      },
      {
        title: this.$t('Menu.SubscriptionInfo'),
        icon: 'mdi-credit-card-outline',
        link: '/subscription'
      },
      {
        title: this.$t('General.InvoiceInfo'),
        icon: 'mdi-receipt-text',
        link: '/invoice-info'
      },
      {
        title: this.$t('Menu.Users'),
        icon: 'mdi-account-group',
        link: '/users'
      },
      {
        title: this.$t('Menu.PaymentHistory'),
        icon: 'mdi-cash-clock',
        link: '/payment-history'
      },
      {
        title: this.$t('Menu.InvoiceHistory'),
        icon: 'mdi-receipt-text-clock',
        link: '/invoice-history'
      }
    ]
  }
};
</script>