<template>
   <div>
      <loading :active="$store.state.setLoading" :can-cancel="false" :is-full-page="true"></loading>
      <v-snackbar :value="$store.state.alert.show" top left :timeout="-1" :content-class="$store.state.alert.type">
        {{ $store.state.alert.message }}
      </v-snackbar>
      <router-view></router-view>
   </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
  name: 'App',
  components: {
    Loading
  }
};
</script>

<style>
.success {
  background-color: green;
}

.error {
  background-color: red;
}

.warning {
  background-color: yellow;
}
.v-tab {
  text-transform: none !important;
}
.v-btn {
    text-transform: unset !important;
}
.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0px !important;
}
.auth-wrapper {
  min-block-size: calc(var(--vh, 0.9vh) * 100);
}
.packages-title {
    color: white;
 }
 .package-price {
    font-size: 30px;
    font-weight: bold;
 }
 .package-1 {
    color: rgb(101, 198, 0);
 }
 .package-2 {
    color: rgb(255, 144, 28);
 }
 .package-3 {
  color: rgb(255, 0, 113);
 }
 .package-4 {
    color: rgb(13, 31, 232);
 }
 .package-5 {
    color: rgb(28, 255, 244);
 }
 .v-data-table-header {
  background-color: #263c6f !important;
}
.v-data-table-header span {
  color: white;
}
.secondary-color {
  color: #263c6f !important;
}
.v-dialog {
  min-width: 50% !important;
}
.secondary-background {
  background-color: #263c6f !important;
}
.logo-area {
  border-radius: 10px !important;
}
.custom-checkbox {
  transform: scale(1.5);
}
.selectable {
    border: 1px black dotted !important;
}
</style>