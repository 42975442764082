<template>
  <v-app>
    <v-main>
      <v-row justify="end">
        <v-menu :rounded="true" offset-y class="language-menu">
          <template v-slot:activator="{ attrs, on }">
            <div class="white--text ma-1 mr-7 mt-5 selected-language" v-bind="attrs" v-on="on">
              <img width="30" :src="`https://flagicons.lipis.dev/flags/4x3/${selectedLang.code === 'en' ? 'us' : selectedLang.code}.svg`"/>
               <span class="ml-2">{{ selectedLang.name  }}</span>
            </div>
          </template>
          <v-list>
            <v-list-item class="language-list" v-for="(lang, index) in this.$store.state.languages" :key="index" link @click="selectLang(lang)">
              <div class="black--text ma-1">
                <img width="30" :src="`https://flagicons.lipis.dev/flags/4x3/${lang.code === 'en' ? 'us' : lang.code}.svg`"/>
                <span class="ml-2">{{ lang.name  }}</span>
              </div>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-row>
      <router-view></router-view>
      <Footer></Footer>
    </v-main>
  </v-app>
</template>
<script>

import Footer from '@/components/Footer.vue';
export default {
    name: 'AuthLayout',
    components: {
      Footer
    },
    data() {
      return {
        selectedLang: {}
      }
    },
    mounted() {
      const langCode = this.$commonHelper.getCurrentLanguage();
      this.selectedLang = this.$store.state.languages.find(l => l.code === langCode);
    },
    methods: {
      async selectLang(lang) {
        this.selectedLang = lang;
        await this.$commonHelper.setLocalizations(lang.code);
      }
    }
}
</script>
<style scoped>
.theme--light.v-application {
  background: #263C6F !important;
}
</style>
<style>
.auth-title {
   font-size: 20px;
}
.auth-text {
   font-size: 16px;
   padding-bottom: 1rem;;
}
.login-logo {
   padding-bottom: 1rem;
   display: block;
   margin-left: auto;
   margin-right: auto;
}
.login-card {
   padding: 2rem;
}
.login-router-link {
  font-size: 15px;
  font-weight: bold;;
}
.language-menu {
  margin-top: 1rem;
  margin-right: 1rem;
}
.language-list {
  min-width: 150px;
}

.selected-language {
  height: 31px;
}
</style>
