import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
      themes: {
        light: {
          primary: '#3fa7dd',
          secondary: '#263C6F'
        },
        dark: {
          primary: '#3fa7dd',
          secondary: '#263C6F'
        },
      },
    }
  })