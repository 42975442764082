<template>
    <v-footer class="portal-footer" relative app>
      <v-row justify="center" no-gutters>
        <v-col cols="12" md="6" lg="6" xs="12">
          <div class="body-1 font-weight-light pt-6 pt-md-0 text-center">
            &copy; {{ new Date().getFullYear() }} HolaScreen
            <a href="https://akfasoft.com/" target="_blank" class="ml-3">Akfasoft</a> {{ $t('General.FooterProduct') }}
          </div>
        </v-col>
        <v-col cols="12" md="6" lg="6" xs="12">
          <div class="body-1 font-weight-light pt-6 pt-md-0 text-center">
            <a href="https://iyzico.com" target="_blank"><img class="iyzico-logo" src="/social/iyzico-logo.png" /></a>
          </div>
        </v-col>
      </v-row>
    </v-footer>
  </template>
  
  <script>
    export default {
      name: 'FooterComp',
    }
  </script>
  
<style>
.portal-footer {
  background-color: #203055 !important;
}
.portal-footer a {
  font-size: 1rem;
  text-decoration: none;
  font-weight: 500;
}
.portal-footer div {
  color: white;
}
.iyzico-logo {
    height: 25px !important;
}
</style>
  