module.exports = {
    rules: {
        email: v => (v && (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(v)) || window.$i18n.t('Validation.EmailMessage'),
        length: len => v => (v && v.length >= len) || window.$i18n.t('Validation.LengthMessage').replace('%s', len),
        exactLength: (actualLength, mesageLength) => v => v && v.length === actualLength || window.$i18n.t('Validation.ExactLengthMessage').replace('%s', mesageLength),
        password: v => (v && (/(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*_'""?+/()=@<>.:;{ }|~-])[a-zA-Z0-9!@#$%^&*_'""?+/()=@<>.:;{}|~-]{6,16}$/).test(v)) ||
        window.$i18n.t('Validation.PasswordMessage'),
        required: v => !!v || window.$i18n.t('General.RequiredMessage'),
        phone: v => (v && (/(^5\d{2})(\s|-)?(\d{3})(\s|-)?(\d{4})/).test(v)) || window.$i18n.t('Validation.PhoneMessage'),
        cardNumber: v => (v && (/^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/).test(v)) ||
        window.$i18n.t('Validation.CardNumberMessage'),
        customerCode: v => (v && (/^[a-z-]*$/).test(v)) || window.$i18n.t('Validation.CustomerCodeMessage'),
        zipCode: v => (v && (/[0-9]{4}$/).test(v)) || window.$i18n.t('Rule.ZipCode'),
        vknOrTckn: v => (v && (/([0-9]){10,11}$/).test(v)) || window.$i18n.t('Rule.VknOrTckn'),
        vkn: v => (v && (/^\s*([0-9]{10})/).test(v)) || window.$i18n.t('Rule.Vkn'),
        tckn: v => (v && (/^\s*([0-9]{11})/).test(v)) || window.$i18n.t('Rule.Tckn'),
        customerName: v => {
          const vals = v.split(' ');
          return vals.length >= 2 && vals.every(a => !!a && a.length >= 3) || window.$i18n.t('Validation.CustomerName')
        }
      }
};