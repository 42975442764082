import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes,
  linkActiveClass: 'active',
});

router.beforeEach ((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (window.$commonHelper.getCurrentToken() == null) {
      next({ path: '/login' })
    } else {
      next();
    }
  } else {
    if (window.$commonHelper.getCurrentToken() && !to.path.includes('/page')) {
      next({ path: '/dashboard' });
    } else {
      next();
    }
  }
})
export default router;
