<template>
  <v-select class="design-select content-z-index" v-model="design" :items="quickDesigns" item-text="name" item-value="id" v-if="quickDesigns.length > 0"
    outlined dense :label="$t('General.SelectQuickDesign')" @input="designChanged" :menu-props="{ closeOnContentClick: true, closeOnClick: true }">
    <template v-slot:prepend-item>
        <v-list-item ripple>
          <v-list-item-content  v-if="design">
            <v-list-item-title>
              <v-btn small color="red white--text" block @click="design = null; designChanged()">
              {{ $t('General.ClearSelection') }}
            </v-btn>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mt-2" v-if="design"></v-divider>
      </template>
  </v-select>
</template>

<script>
export default {
props: {
},
data() {
  return {
    quickDesigns: [],
    design: null,
    lastWidth: null,
    lastHeight: null
  };
},
methods: {
    async getData() {
      this.quickDesigns = await this.$api.get('common/quick-designs');
    },
    async designChanged(id) {
        if (id) {
          this.lastHeight = this.$store.state.screen.height;
          this.lastWidth = this.$store.state.screen.width;

          const result = await this.$api.get(`common/quick-design?id=${id}`);
           if (result.design) {
             const designComponents = result.design.components ?  Array.from(result.design.components) : [];
              this.$store.commit('set', ['designComponents', designComponents]);
              if (result.design.screen) {
                this.$store.commit('set', ['screen', result.design.screen]);
              }
          }
        } else {
          this.$store.commit('set', ['designComponents', []]);
          this.$store.commit('set', ['screen', {
            backgroundColor: '#ffffff80',
            backgroundImage: {
                model: {
                    value: '',
                    file: null
                }
            },
            height: this.lastHeight || 900,
            width: this.lastWidth || 1600
          }]);
        }
    }
},
async mounted() {
  await this.getData();
}
};
</script>
<style>
.content-z-index {
  z-index: 10 !important;
}
.design-select .v-select__selections {
  width: 200px !important;
} 
</style>